<script setup>
import { timeline, stagger } from 'motion'

const svgImage = ref(null)
const paths = ref(null)
const pathsanim = ref(null)
const draw = progress => ({
    strokeDashoffset: 1 - progress,
    visibility: 'visible',
})
const enterSvg = () => {
    pathsanim.value.play()
}

const leaveSvg = () => {
    pathsanim.value.pause()
    pathsanim.value.currentTime = 0
}

defineProps({
    svg: {
        type: String,
        default: '',
    },
})

onMounted(() => {
    if (svgImage.value) {
        paths.value = svgImage.value.querySelectorAll('path,circle,polygon,rect,ellipse,polyline,line')
        for (let i = 0, il = paths.value.length; i < il; ++i) {
            const path = paths.value[i]
            path.setAttribute('pathLength', '1')
        }
        pathsanim.value = timeline([
            [paths.value, draw(1), { duration: 2, delay: stagger(0.05) }],
        ])
        pathsanim.value.pause()
    }
})
</script>

<template>
    <VisibleProgress
        v-on:enter="enterSvg"
        v-on:leave="leaveSvg"
    >
        <div
            ref="svgImage"
            v-html="svg"
        />
    </VisibleProgress>
</template>
